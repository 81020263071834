<template>
  <footer v-show="model">
    <div class="left-col">
      <div class="slide" ref="slide">
        <img v-if="logo" :src="imagePath" />
        <div class="title" ref="title"></div>
      </div>
    </div>
    <div class="right-col">
      <b-img src="images/icon/clock.svg" width="28"></b-img>
      <span>{{ model.time }}</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-bar",
  props: ["data"],
  components: {},
  data() {
    return {
      i: 0,
      text: "",
      destroyed: false
    };
  },
  computed: {
    model() {
      return this.$store.state.footerBar;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.logo}`;
    },
    logo() {
      return this.$store.state.logo;
    },
    footerTexts() {
      return this.model.footerTexts;
    }
  },
  created() {
    this.$store.dispatch("footerBar/startUpdateTimeInterval");
  },
  mounted() {
    this.getFooterText();
    var timer = setInterval(() => {
      if (!this.destroyed)
        this.getFooterText();
      else
        clearInterval(timer);
    }, 5000);
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  methods: {
    getFooterText() {
      if (this.i >= this.footerTexts.length) {
        this.i = 0;
      }
      var i = this.i++;
      var texts = this.footerTexts;
      var slide = this.$refs.slide;
      var text = this.$refs.title;
      var s = slide.style;
      s.opacity = 1;
      (function fade() {
        if (s.opacity > 0) {
          s.opacity -= 0.1;
          setTimeout(fade, 50);
        } else {
          text.innerHTML = texts[i];
          s.opacity = 1;
        }
      })();
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(58, 90, 143);
  background: radial-gradient(circle,
      rgba(58, 90, 143, 1) 0%,
      rgba(27, 40, 74, 1) 100%);
  color: white;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  z-index: 999;

  .left-col {
    display: inline-block;
    padding: 7px 0;
    width: calc(100% - 243px);
    white-space: nowrap;
    overflow: hidden;

    img {
      width: auto;
      height: 38px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .title {
      max-height: 38px;
      max-width: calc(100% - 125px);
      display: inline-block;
      font-size: 31px;
      font-family: Montserrat-Regular;
      margin-left: 15px;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .right-col {
    display: inline-block;
    padding: 10px;
    width: 240px;
    background: rgb(27, 167, 226);
    background: linear-gradient(90deg,
        rgba(27, 167, 226, 1) 0%,
        rgba(23, 153, 158, 1) 100%);

    i {
      font-size: 34px;
      display: inline-block;
      line-height: 38px;
      margin-right: 20px;
    }

    img {
      display: inline-block;
      margin-right: 10px;
      margin-top: -3px;
    }

    span {
      font-size: 32px;
      font-family: Montserrat-Regular;
      letter-spacing: 1px;
    }
  }
}

@media (max-width: 1365px) {
  footer {
    .left-col {
      padding: 8px;
    }

    .right-col {
      padding: 8px;

      i {
        font-size: 30px;
      }

      span {
        font-size: 33px;
      }
    }
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {}

@media (min-width: 1950px) and (max-width: 2048px) {}

@media (min-width: 2049px) {
  footer {
    font-size: 50px;
    line-height: 70px;

    .left-col {
      width: calc(100% - 400px);
      padding: 24px;

      img {
        height: 70px;
        margin-right: 10px;
      }

      .title {
        max-height: 70px;
        overflow: hidden;
      }
    }

    .right-col {
      width: 400px;
      padding: 24px;

      i {
        font-size: 50px;
        line-height: 70px;
        margin-right: 40px;
      }

      span {
        font-size: 66px;
      }
    }
  }
}</style>